<template>
  <div class="schedule-calendar-container" style="margin-top: 16px">
    <!-- calendar-top -->
    <div class="calendar-labels">
      <div class="label"><i class="label-mark mark-a"></i><span class="label-text">전일제(주중)</span></div>
      <div class="label"><i class="label-mark mark-b"></i><span class="label-text">전일제(주말포함)</span></div>
      <div class="label"><i class="label-mark mark-c"></i><span class="label-text">주말</span></div>
    </div>
    <div class="calendar-body" style="margin-bottom: 90px">
      <div class="calendar-month mb-1">
        <div class="calendar-scroll">
          <a href="javascript:" class="month" :class="{'is-active': defMonth === 0}" @click="changeMonth(0)">1월</a>
          <a href="javascript:" class="month" :class="{'is-active': defMonth === 1}" @click="changeMonth(1)">2월</a>
          <a href="javascript:" class="month" :class="{'is-active': defMonth === 2}" @click="changeMonth(2)">3월</a>
          <a href="javascript:" class="month" :class="{'is-active': defMonth === 3}" @click="changeMonth(3)">4월</a>
          <a href="javascript:" class="month" :class="{'is-active': defMonth === 4}" @click="changeMonth(4)">5월</a>
          <a href="javascript:" class="month" :class="{'is-active': defMonth === 5}" @click="changeMonth(5)">6월</a>
          <a href="javascript:" class="month" :class="{'is-active': defMonth === 6}" @click="changeMonth(6)">7월</a>
          <a href="javascript:" class="month" :class="{'is-active': defMonth === 7}" @click="changeMonth(7)">8월</a>
          <a href="javascript:" class="month" :class="{'is-active': defMonth === 8}" @click="changeMonth(8)">9월</a>
          <a href="javascript:" class="month" :class="{'is-active': defMonth === 9}" @click="changeMonth(9)">10월</a>
          <a href="javascript:" class="month" :class="{'is-active': defMonth === 10}" @click="changeMonth(10)">11월</a>
          <a href="javascript:" class="month" :class="{'is-active': defMonth === 11}" @click="changeMonth(11)">12월</a>
        </div>
      </div>
      <div class="calendar-scroll">
        <div class="calendar-grid">
        <div class="grid-week">일</div>
        <div class="grid-week">월</div>
        <div class="grid-week">화</div>
        <div class="grid-week">수</div>
        <div class="grid-week">목</div>
        <div class="grid-week">금</div>
        <div class="grid-week">토</div>
        <template v-for="(item, idx) in calItems" :key="idx">
            <div class="grid-day"
                 :class="{'today': timestampToDateFormat(currentTs, 'yyyyMMdd') == timestampToDateFormat(item.calDt, 'yyyyMMdd') &&
                                   timestampToDateFormat(currentTs, 'yyyyMMdd').substring(0,6) == crseYear.concat(getMonth[defMonth])}">
              <template v-if="item.basMm == crseYear.concat(getMonth[defMonth])">
                <span class="day"  :class="{ 'text-red' : isWeekend(item.calDt) }">
                  {{ parseInt(timestampToDateFormat(item.calDt, 'dd')) }}</span>
                <template v-if="isStack(item, idx)">
                  <a href="javascript:" @click="goApply" v-html="stackCalendar(item, idx)"></a>
                  <div class="dropdown" v-if="getDistCnt(item, idx) > 3" :style="{'width': '100px','position': 'absolute','left': '120px','top': `${113 + (3 * (parseInt(idx / 7) + 1)) - (2 * parseInt(idx / 7)) }px`}">
                    <button class="dropdown-btn" style="width:100px; justify-content: flex-end; padding-right: 7px" @click="toggleSub(item, idx)">
<!--                      <strong class="text">. . .</strong>-->
                      <strong class="text mt-1 text-decoration-underline" style="color:darkgray">더보기 +{{ getDistCnt(item, idx) }}</strong>
                    </button>
                  </div>
                </template>
              </template>
              <template v-else>
                <span class="day-off">{{ parseInt(timestampToDateFormat(item.calDt, 'dd')) }}</span>
              </template>
            </div>
        </template>
      </div>
      </div>
    </div>

    <div class="modal" :class="calendarSub.isShow ? 'is-active' : ''">
      <div class="modal-dimmed"></div>
      <div class="modal-contents" style="padding: 15px">
        <div class="dropdown-schedule-header d-flex justify-content-between align-items-center" style="padding: 0px 5px 2px 5px">
          <h4 class="dropdown-schedule-title">{{ timestampToDateFormat(calendarSub.dispDt, 'yyyy.MM.dd') }}</h4>
          <button class="dropdown-schedule-btn" @click="calendarSub.isShow = false"><i class="icon-x"></i></button>
        </div>
        <template v-for="(dist, distIdx) in calendarSub.dists" :key="distIdx">
          <a href="javascript:" @click="goApplySub(dist.distCrseSn)">
            <article class="schedule-item" style="margin-bottom: 5px"
                     :data-type="dist.grpOperPeridCdDcd === '2026001' ? 'a' : dist.grpOperPeridCdDcd === '2026002' ? 'b' : 'c'">
              <p class="schedule-title">{{ dist.distCrseNm }}</p></article></a>
        </template>
      </div>
      <button class="modal-close-btn"><i class="icon-close"></i></button>
    </div>
  </div>

</template>

<script>

import {ref, reactive} from 'vue';
import {timestampToDateFormat} from '@/assets/js/util';
import {useRouter} from "vue-router";
import {holyDay} from "@/assets/js/holy-day";

export default {
  name: 'ApplyCalendar',
  components: {},
  props: {
    items: Array,
    learns: Array,
    crseYear: String,
  },
  setup(props) {

    const router = useRouter();
    const calItems = ref([]);
    const getMonth = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    const defMonth = ref(0);
    const currentTs = new Date().getTime();
    const dateRowCount = ref(0);
    const weeklyStart = ref([]);
    const weeklyEnd = ref([]);
    const weeklys = ref([]);
    const occupied = ref([]);
    const weekOccupied = ref([]);
    const distCnt = ref(0);
    const calendarSub = reactive({
      isShow: false,
      dispDt: '',
      posLeft: 0,
      posTop: 0,
      dists : [],
    });

    const changeMonth = (mVal) => {
      defMonth.value = mVal;
      calendarSub.isShow = false
      getWeekNum();
    };

    const isWeekend = (date) => {


      const week = ['일', '월', '화', '수', '목', '금', '토'];

      const dayOfWeek = week[new Date(date).getDay()];

      console.log(date, dayOfWeek)
      if(dayOfWeek === '토' || dayOfWeek === '일') return true;

      return holyDay[timestampToDateFormat(date, 'yyyyMMdd')];
      // return false;
    }


    const getWeekNum = () => {
      calItems.value = [];
      weekOccupied.value = [
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0]
      ];

      const started = props.items.filter(
          x => x.basDd === 'F' && x.basMm === props.crseYear.concat(getMonth[defMonth.value] + ''));
      const ended = props.items.filter(
          x => x.basDd === 'L' && x.basMm === props.crseYear.concat(getMonth[defMonth.value] + ''));

      if (started[0].dayWeek !== 1) {
        let snum = started[0].rnum - (started[0].dayWeek - 1);
        // console.log(snum);
        calItems.value.push(...props.items.filter(x => x.rnum >= snum && x.rnum < started[0].rnum));
      }
      calItems.value.push(...props.items.filter(y => y.basMm === props.crseYear.concat(getMonth[defMonth.value] + '')));
      if (ended[0].dayWeek !== 7) {
        let snum = ended[0].rnum + (7 - ended[0].dayWeek);
        // console.log(snum);
        calItems.value.push(...props.items.filter(x => x.rnum > ended[0].rnum && x.rnum <= snum));
      }

      weeklyStart.value = [];
      weeklyEnd.value = [];
      weeklys.value = [];
      calItems.value.forEach(x => {
        // console.log(x.basMm, props.crseYear.concat(getMonth[defMonth]), x.basMm == props.crseYear.concat(getMonth[defMonth]));
        if (x.basMm == props.crseYear.concat(getMonth[defMonth.value])) {
          let sDt = x.dayWeek === 1 ? x.calDt : x.basDd === 'F' ? x.calDt : 0;
          let eDt = x.dayWeek === 7 ? x.calDt : x.basDd === 'L' ? x.calDt : 0;
          if (sDt > 0)
            // weeklyStart.value.push({sdt: sDt > 0 ? timestampToDateFormat(sDt, 'dd') : 0});
            weeklyStart.value.push({sdt: sDt > 0 ? sDt : 0});
          if (eDt > 0)
            // weeklyEnd.value.push({edt: eDt > 0 ? timestampToDateFormat(eDt, 'dd') : 0});
            weeklyEnd.value.push({edt: eDt > 0 ? eDt : 0});
        }
      });
      weeklyStart.value.forEach((x, idx) => {
        weeklys.value.push({sdt: x.sdt});
        weeklyEnd.value.forEach((y, idy) => {
          if (idx === idy) {
            weeklys.value[idy].edt = y.edt;
          }
        })
      })
      // console.log(weeklys.value);

      distItems.value = [];
      weeklys.value.forEach(a => {
        props.learns.forEach(p => {
          if (p.endDt >= a.sdt && p.bgngDt <= a.edt) {
            if (p.grpOperPeridCdDcd === '2026002') {
              distItems.value.push(
                  { distCrseSn: p.distCrseSn, distCrseNm: p.distCrseNm, grpOperPeridCdDcd: p.grpOperPeridCdDcd,
                    bgngDt: p.bgngDt >= a.sdt ? p.bgngDt : a.sdt,
                    endDt: p.endDt <= a.edt ? p.endDt : a.edt });
            } else if (p.grpOperPeridCdDcd === '2026003') {
              const dayWeekS = calItems.value.filter(x => x.calDt === a.sdt)[0].dayWeek;
              const dayWeekE = calItems.value.filter(x => x.calDt === a.edt)[0].dayWeek;
              if (a.sdt === a.edt) {
                // console.log(timestampToDateFormat(a.sdt, 'dd'));
                if (a.sdt >= p.bgngDt && a.sdt <= p.endDt) {
                  if (dayWeekS === 1 || dayWeekS === 7) {
                    distItems.value.push({
                      distCrseSn: p.distCrseSn, distCrseNm: p.distCrseNm, grpOperPeridCdDcd: p.grpOperPeridCdDcd,
                      bgngDt: a.sdt,
                      endDt: a.sdt
                    });
                  }
                }
              } else {
                // console.log(timestampToDateFormat(a.sdt, 'dd'),timestampToDateFormat(a.edt, 'dd'));
                if (a.sdt >= p.bgngDt && a.sdt <= p.endDt) {
                  if (dayWeekS === 1 || dayWeekS === 7) {
                    distItems.value.push({
                      distCrseSn: p.distCrseSn, distCrseNm: p.distCrseNm, grpOperPeridCdDcd: p.grpOperPeridCdDcd,
                      bgngDt: a.sdt,
                      endDt: a.sdt
                    });
                  }
                }
                if (a.edt >= p.bgngDt && a.edt <= p.endDt) {
                  if (dayWeekE === 1 || dayWeekE === 7) {
                    distItems.value.push({
                      distCrseSn: p.distCrseSn, distCrseNm: p.distCrseNm, grpOperPeridCdDcd: p.grpOperPeridCdDcd,
                      bgngDt: a.edt,
                      endDt: a.edt
                    });
                  }
                }
              }
            } else {
              const dayWeekS = calItems.value.filter(x => x.calDt === a.sdt)[0].dayWeek;
              const dayWeekE = calItems.value.filter(x => x.calDt === a.edt)[0].dayWeek;
              if (a.sdt !== a.edt) {
                // console.log(timestampToDateFormat(a.sdt, 'dd'),timestampToDateFormat(a.edt, 'dd'));
                let frdt = 0;
                let todt = 0
                if (p.bgngDt <= a.sdt) {
                  if (dayWeekS === 1) {
                    let rnum = calItems.value.filter(x => x.calDt === a.sdt)[0].rnum + 1;
                    // console.log(calItems.value.filter(x => x.calDt === a.sdt)[0].rnum, calItems.value.filter(x => x.rnum === rnum)[0].rnum);
                    frdt = calItems.value.filter(x => x.rnum === rnum)[0].calDt;
                  } else {
                    frdt = a.sdt;
                  }
                }else {
                  frdt = p.bgngDt;
                }
                if (p.endDt >= a.edt) {
                  if (dayWeekE === 7) {
                    let rnum = calItems.value.filter(x => x.calDt === a.edt)[0].rnum - 1;
                    todt = calItems.value.filter(x => x.rnum === rnum)[0].calDt;
                  } else {
                    todt = a.edt;
                  }
                } else {
                  todt = p.endDt;
                }
                distItems.value.push({
                  distCrseSn: p.distCrseSn, distCrseNm: p.distCrseNm, grpOperPeridCdDcd: p.grpOperPeridCdDcd,
                  bgngDt: frdt,
                  endDt: todt
                });
              }
            }
          }
        });
      });
      // console.log(distItems.value);

      calItems.value.forEach((item, itemIdx) => {
        // console.log(timestampToDateFormat(item.calDt, 'dd'));
        let rowNum = parseInt(itemIdx / 7);
        let colNum = parseInt(item.dayWeek) - 1;
        if (item.dayWeek === 1) {
          weekOccupied.value = [
            [0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0]
          ];
        }

        // if (rowNum === 0) {
          if (distItems.value.length > 0) {
            distItems.value.forEach(x => {
                // console.log(timestampToDateFormat(item.calDt, 'dd'),item.calDt >= x.bgngDt && item.calDt <= x.endDt);
                for (let i = 0; i < 3; i++) {
                  if (item.calDt >= x.bgngDt && item.calDt <= x.endDt) {
                    let dayDiff = 0;
                    if (item.calDt === x.bgngDt) {
                      dayDiff = ((x.endDt - x.bgngDt) / (1000 * 60 * 60 * 24) + 1);
                    }
                    // console.log(dayDiff);
                    if (weekOccupied.value[i][colNum] === 0) {
                      if (dayDiff > 0) {
                        for (let j = 0; j < dayDiff; j++) {
                          weekOccupied.value[i][colNum + j] = {distCrseSn: x.distCrseSn, diff: j, grpOperPeridCdDcd: x.grpOperPeridCdDcd};
                        }
                      }
                      break;
                    }
                  }
                }

            });
            // console.log(weekOccupied.value);
            occupied.value[rowNum] = weekOccupied.value;
          }
        // }
      });
      // console.log(occupied.value);
    }

    const distItems = ref([]);

    const isStack = (item) => {
      const checked = distItems.value.filter(x => item.calDt >= x.bgngDt && item.calDt <= x.endDt);
      return checked.length > 0;
    }

    const stackCalendar = (item, itemIdx) => {
      let contents = '';
      let rowNum = parseInt(itemIdx / 7);
      let colNum = parseInt(item.dayWeek) - 1;
      const tmpArr = occupied.value[rowNum];
      if (distItems.value.length > 0) {
        distItems.value.forEach(x => {
          let dayDiff = ((x.endDt - x.bgngDt) / (1000 * 60 * 60 * 24) + 1) + '';
          let basicGap = 5;
          if (item.calDt === x.bgngDt) {
            for (let i = 0; i < 3; i++) {
              if (tmpArr[i][colNum].distCrseSn > 0 && tmpArr[i][colNum].distCrseSn === x.distCrseSn) {
                if (i === 0) {
                  basicGap = 5;
                } else {
                    if (i === 1) {
                      basicGap = tmpArr[0][colNum].diff === 0 ? 5 : 21;
                    } else if (i === 2) {
                      basicGap = tmpArr[1][colNum].diff === 0 ? tmpArr[0][colNum].diff === 0 ? 5 : 13 : tmpArr[0][colNum].diff === 0 ? 21 : 36;
                    }
                }
                contents +=`<div class="schedule" data-row="1" style="position: relative; top: ${basicGap}px">
                            <article class="schedule-item" id="a-${i}-${x.distCrseSn}"
                            data-column="${dayDiff}"
                            data-type="${tmpArr[i][colNum].grpOperPeridCdDcd === '2026001' ? 'a' :
                                          tmpArr[i][colNum].grpOperPeridCdDcd === '2026002' ? 'b' : 'c'}"
                            style="margin-top: ${basicGap}px">
                            <p class="schedule-title" id="p-${i}-${x.distCrseSn}">${x.distCrseNm}</p></article></div>`;
              }
            }
          }
        });
      }

      return contents;
    }

    const goApply = (e) => {
      // console.log(e.target.id.substring(4));
      if (e.target.id) {
        router.push({name: 'ApplyTrainView', params: {distCrseSn: parseInt(e.target.id.substring(4))}});
      }
    }

    const goApplySub = (distCrseSn) => {
      if (distCrseSn) {
        router.push({name: 'ApplyTrainView', params: {distCrseSn: parseInt(distCrseSn)}});
      }
    }

    const getDistCnt = (item) => {
      let rowCnt = distItems.value.filter(x => item.calDt >= x.bgngDt && item.calDt <= x.endDt).length;
      return rowCnt;
    }

    const getStackCnt = (item, itemx) => {
      let rowNum = parseInt(itemx / 7);
      let colNum = parseInt(item.dayWeek) - 1;
      const tmpArr = occupied.value[rowNum];
      let stackCnt = 0;
      for (let x = 0; x < 3 ; x++) {
        if (tmpArr[x][colNum].diff === 0) {
          stackCnt++;
        }
      }
      console.log(stackCnt);
      return stackCnt;
    }

    const toggleSub = (item, itemx) => {
      let rowNum = parseInt(itemx / 7);
      if (calendarSub.isShow) {
        if (calendarSub.dispDt === item.calDt) {
          calendarSub.isShow = false;
        }
      } else {
        calendarSub.isShow = true;
      }
      let distCnt = distItems.value.filter(x => item.calDt >= x.bgngDt && item.calDt <= x.endDt).length;
      calendarSub.dispDt = item.calDt;
      calendarSub.posLeft = item.dayWeek === 1 ? 36 : item.dayWeek === 7 ? 1165 : 60 + (221 * (item.dayWeek - 1));
      calendarSub.posTop = weeklys.value.length == (rowNum + 1) ? (184 * (weeklys.value.length - rowNum)) - ((45 - (distCnt - 1) * 5) - distCnt * 34) : (184 * (weeklys.value.length - rowNum)) - 151;
      calendarSub.dists = distItems.value.filter(x => item.calDt >= x.bgngDt && item.calDt <= x.endDt);
    }

    defMonth.value = new Date().getMonth();
    getWeekNum();

    return {
      calItems,
      timestampToDateFormat,
      changeMonth,
      defMonth,
      getMonth,
      currentTs,
      stackCalendar,
      dateRowCount,
      isStack,
      goApply,
      getDistCnt,
      distCnt,
      toggleSub,
      calendarSub,
      goApplySub,
      getStackCnt,
      isWeekend,
    };
  },
};
</script>

<style scoped>

.lxp-mobile-layout .schedule-calendar-container .calendar-grid {
  display: grid;
  border-top: 1px solid var(--kb-border-color);
  grid-template-columns: repeat(7, 221px);
  min-width: 1550px;
}

.lxp-mobile-layout .schedule-calendar-container .grid-day:nth-child(7n+7) .day {
  color: var(--kb-red);
}

.lxp-mobile-layout .schedule-calendar-container .grid-day:nth-child(7n+1) .day {
  color: var(--kb-red);
}

.day-off {
  //color: #d9d8d7;
  color: #ffffff;
}

.lxp-mobile-layout .schedule-calendar-container .grid-day.today {
  background-color: rgba(255, 204, 0, 0.58);
  //background-color: #EAEAEA93;
}

.lxp-mobile-layout .schedule-calendar-container .schedule-item[data-type="x"] {
  background-color: #FFFFFF;
}

.lxp-mobile-layout .schedule-calendar-container .calendar-top .top-title {
  margin-bottom: 0px;
}

.lxp-mobile-layout .schedule-calendar-container .calendar-labels {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
}

.lxp-mobile-layout .schedule-calendar-container .calendar-month {
  border-top: 1px solid var(--kb-border-color);
  margin: 0 10px;
  padding: 5px;
}

.lxp-mobile-layout .schedule-calendar-container .calendar-scroll {
  overflow-x: auto;
  padding: 0 10px;
}

</style>
